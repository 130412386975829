<template>
  <v-main>

    <v-container
        id="view-claims-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-bank-transfer-in"
          class="px-5 py-3"
      >

        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   outlined
                   dark
                   :key="error.id"
                   show variant="danger"
                   v-for="error in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-subtitle-2">Dependent</th>
                  <th class="text-subtitle-2">Relationship</th>
                  <th class="text-subtitle-2">Cause</th>
                  <th class="text-subtitle-2">Incident Date</th>
                  <th class="text-subtitle-2">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="claim in claims"
                  :key="claim.id"
                >
                  <td>
                    <router-link :to="{name: 'edit_claim', params: {id: claim.id}}">
                      {{ claim.dependent.firstName }} {{ claim.dependent.middleName }} {{ claim.dependent.lastName }}
                    </router-link>
                  </td>
                  <td>{{ claim.dependent.relationType.name }}</td>
                  <td>{{ claim.cause }}</td>
                  <td>{{ claim.incidentDate | formatDate }}</td>
                  <td>{{ claimStatusMap[claim.status] }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>


      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import geoUtils from '@/utils/geography'
import constants from '@/utils/constants'
import {mapGetters} from "vuex";

export default {
  name: 'CoreViewClaimsView',

  created() {
    this.$gtag.event('view_claims')

    this.userId = this.getUserId
    this.fetchClaims()
  },

  computed: {
    ...mapGetters({
      getUserId: 'getUserId'
    }),
  },

  methods: {
    fetchClaims() {
      api
          .fetchClaims(this.userId)
          .then(response => {
            this.claims = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      errors: [],
      userId: null,
      user: {
        country: 'US',
        idType: {}
      },
      claims: [],
      userStatusMap: constants.getUserStatusMap(),
      claimStatusMap: constants.getClaimStatusMap()
    }
  }
}
</script>
